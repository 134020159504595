import { useState } from "react";
import Button from "../toolBox/Button";
import Input from "../toolBox/Input";
import InputField from "../toolBox/InputField";
import { AnimatePresence, motion } from "framer-motion";
import styles from "./Forecast.module.css";

const tooltip = {
  customStyle: {
    width: 240,
    left: "-40px !important",
    top: "25px !important",
    boxSizing: "border-box",
  },
  customArrowStyle: {
    left: "45px !important",
  },
};

const targetServiceLevel = {
  label: "Target Service Level",
  value: 80,
  patternSignifier: "%",
  tooltip: {
    text: "The percentage of interactions to handle within your defined threshold. For example, if your threshold is 20 seconds, 80% means resolving 80% of interactions within that time.",
    ...tooltip,
  },
};
const serviceLevelThreshold = {
  label: "Service Level Treshold",
  value: 20,
  patternSignifier: "sec",
  tooltip: {
    text: "The time to handle a percentage of interactions. For example, if your service level target is 80%, 20 seconds means resolving 80% of interactions within that time.",
    ...tooltip,
  },
};
const averageHandlingTime = {
  label: "Average Handling Time",
  value: 300,
  patternSignifier: "sec",
  tooltip: {
    text: "The average time it takes to handle one interaction, including talk time, hold time, and post-interaction tasks like follow-ups and note-taking.",
    ...tooltip,
    customStyle: {
      ...tooltip.customStyle,
      left: "-50px !important",
    },
    customArrowStyle: {
      ...tooltip.customArrowStyle,
      left: "55px !important",
    },
  },
};
const averageHandlingTimeLinear = {
  label: "Average Handling Time",
  value: 300,
  patternSignifier: "min",
  tooltip: {
    text: "The average time it takes to handle one interaction, including talk time, hold time, and post-interaction tasks like follow-ups and note-taking.",
    ...tooltip,
    customStyle: {
      ...tooltip.customStyle,
      left: "-50px !important",
    },
    customArrowStyle: {
      ...tooltip.customArrowStyle,
      left: "55px !important",
    },
  },
};
const averagePatienceTime = {
  label: "Average Patience Time",
  value: 120,
  patternSignifier: "sec",
  tooltip: {
    text: "The average time a customer is willing to wait before abandoning the queue.",
    ...tooltip,
    customStyle: {
      ...tooltip.customStyle,
      left: "-50px !important",
    },
    customArrowStyle: {
      ...tooltip.customArrowStyle,
      left: "55px !important",
    },
  },
};
const concurrency = {
  label: "Concurrency",
  value: 1,
  patternSignifier: "#",
  tooltip: {
    text: "The number of interactions an agent can handle simultaneously (e.g., 1 for calls, 2-3 for chat).",
    ...tooltip,
  },
};
const relativeOccupancy = {
  label: "Relative Occupancy",
  value: 85,
  patternSignifier: "%",
  tooltip: {
    text: "The percentage of time agents spend actively handling each individual interaction, accounting for breaks and recovery time.",
    ...tooltip,
  },
};
const shrinkage = {
  label: "Shrinkage",
  value: 30,
  patternSignifier: "%",
  tooltip: {
    text: "The percentage of agent time lost to non-productive activities like training, breaks, or meetings.",
    ...tooltip,
  },
};
const targetResponseTime = {
  label: "Target Response Time",
  value: 120,
  patternSignifier: "min",
  tooltip: {
    text: "The maximum time allowed to respond to a case. Optional for work without strict response time requirements.",
    ...tooltip,
  },
};
const productivityFactor = {
  label: "Productivity Factor",
  value: 80,
  patternSignifier: "%",
  tooltip: {
    text: "The efficiency rate at which agents complete work compared to standard expectations. Accounts for experience levels, tool efficiency, and process maturity.",
    ...tooltip,
  },
};
const minimumStaffing = {
  label: "Minimum Staffing",
  value: null,
  patternSignifier: "FTE",
  tooltip: {
    text: "The minimum number of staff required during operating hours, regardless of volume. This is an optional setting to ensure baseline coverage.",
    ...tooltip,
  },
};
const maximumStaffing = {
  label: "Maximum Staffing",
  value: null,
  patternSignifier: "FTE",
  tooltip: {
    text: "The maximum number of staff permitted during operating hours to cap resource allocation. This is an optional setting.",
    ...tooltip,
  },
};

const STAFFING_PARAMETERS = {
  erlang_c: [
    {
      type: "header",
      name: "Service Level Goals",
    },
    targetServiceLevel,
    serviceLevelThreshold,
    {
      type: "header",
      name: "Channel Settings",
    },
    averageHandlingTime,
    concurrency,
    {
      type: "header",
      name: "Efficiency Settings",
    },
    relativeOccupancy,
    shrinkage,
    {
      type: "header",
      name: "Staffing Constraints",
    },
    minimumStaffing,
    maximumStaffing,
  ],
  erlang_a: [
    {
      type: "header",
      name: "Service Level Goals",
    },
    targetServiceLevel,
    serviceLevelThreshold,
    {
      type: "header",
      name: "Channel Settings",
    },
    averageHandlingTime,
    averagePatienceTime,
    concurrency,
    {
      type: "header",
      name: "Efficiency Settings",
    },
    relativeOccupancy,
    shrinkage,
    {
      type: "header",
      name: "Staffing Constraints",
    },
    minimumStaffing,
    maximumStaffing,
  ],
  linear: [
    {
      type: "header",
      name: "Channel Settings",
    },
    averageHandlingTimeLinear,
    targetResponseTime,
    concurrency,
    {
      type: "header",
      name: "Efficiency Settings",
    },
    productivityFactor,
    shrinkage,
    {
      type: "header",
      name: "Staffing Constraints",
    },
    minimumStaffing,
    maximumStaffing,
  ],
};

export default function StaffingParameters(props: {
  handleClose: () => void;
  staffingParameters: any;
}) {
  const [staffingParameters, setStaffingParameters] = useState(
    props.staffingParameters
  );

  const calculationMethod = staffingParameters.calculation_method
    .toLowerCase()
    .replace(" ", "_");
  const parameters = STAFFING_PARAMETERS[
    calculationMethod as keyof typeof STAFFING_PARAMETERS
  ].map((parameter) => {
    const label = parameter.label?.toLowerCase().replaceAll(" ", "_");
    return {
      ...parameter,
      value: staffingParameters[calculationMethod][label] || "",
    };
  });
  return (
    <AnimatePresence>
      <motion.div
        style={{
          width: 375,
          position: "relative",
          background: "white",
          borderLeft: "1px solid #E0E0E0",
          boxSizing: "border-box",
        }}
        initial={{ right: -70 }}
        animate={{ right: 0 }}
        exit={{ right: -70 }}
        transition={{
          duration: 0.5,
          ease: [0, 1, 0.5, 1],
        }}
      >
        <div
          style={{
            width: "100%",
            height: 48,
            borderBottom: "1px solid #E0E0E0",
            boxSizing: "border-box",
            textAlign: "center",
            lineHeight: "48px",
            background: "#f9f9f9",
          }}
        >
          Staffing parameters
          <span
            style={{
              position: "absolute",
              right: 16,
              top: 0,
              cursor: "pointer",
              fontSize: 20,
              color: "rgba(0,0,0,0.87)",
            }}
            className="bi_interface-cross"
            onClick={props.handleClose}
          />
        </div>
        <div className={styles["staffing-parameters-body"]}>
          <InputField
            label="Calculation Method"
            value={staffingParameters.calculation_method}
            options={["Erlang C", "Erlang A", "Linear"].map((key) => ({
              id: key.replace("_", " "),
              name: key,
              action: () => {
                const copy = { ...staffingParameters };
                copy.calculation_method = key;
                setStaffingParameters(copy);
              },
              selected: key === staffingParameters.calculation_method,
            }))}
            disabled
          />
          {parameters.map((parameter) => {
            if (parameter.type === "header") {
              return (
                <div
                  key={parameter.name}
                  style={{ fontSize: 14, fontWeight: "bold" }}
                >
                  {parameter.name}
                </div>
              );
            }
            return (
              <Input
                key={parameter.label}
                label={parameter.label}
                value={parameter.value || "-"}
                patternSignifier={parameter.patternSignifier}
                disabled
                tooltip={parameter.tooltip ? parameter.tooltip : undefined}
              />
            );
          })}
        </div>
        {/* <div
          style={{
            position: "absolute",
            bottom: 0,
            width: "100%",
            height: 70,
            borderTop: "1px solid #E0E0E0",
            boxSizing: "border-box",
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            padding: "0 24px",
            background: "white",
          }}
        >
          <Button name="Cancel" theme="grey-border" size="large" />
          <Button name="Update" theme="blue" size="large" />
        </div> */}
      </motion.div>
    </AnimatePresence>
  );
}
