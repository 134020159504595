import { Switch, Route, Link } from "react-router-dom";
import Dataset from "./Dataset";
import Moment from "moment-timezone";
import styles from "./Forecast.module.css";
import StatusLabel from "./StatusLabel";
import { ApiIcon, Csv01Icon } from "@hugeicons/react";
import utils from "./utils";
import Search from "../toolBox/Search";
import { useState } from "react";
import matchSorter from "match-sorter";
import EmptyState from "../toolBox/EmptyState";

import UpActive from "../../assets/images/up-active.svg";
import UpInActive from "../../assets/images/up-inactive.svg";
import DownActive from "../../assets/images/down-active.svg";
import DownInActive from "../../assets/images/down-inactive.svg";

export default function Datasets({
  datasets,
  format12h,
}: {
  datasets: {
    id: string;
    name: string;
    status: string;
    source: string;
    uploadInterval: string;
    lastDataPoint?: string;
    lastForecastRun?: string;
    datasetTimezone: string;
    forecastTimezone: string;
    staffingParameters: any;
  }[];
  format12h: boolean;
}) {
  return (
    <div>
      <Switch>
        <Route
          path="/forecasts/datasets"
          exact
          render={(props) => {
            return (
              <DatasetList
                {...props}
                datasets={datasets}
                format12h={format12h}
              />
            );
          }}
        />
        <Route
          path="/forecasts/datasets/:id"
          render={() => {
            return <Dataset datasets={datasets} format12h={format12h} />;
          }}
        />
      </Switch>
    </div>
  );
}

function handleSortDatasets(
  datasets: {
    id: string;
    name: string;
    status: string;
    source: string;
    datasetTimezone: string;
    forecastTimezone: string;
    uploadInterval: string;
    lastDataPoint?: string;
    lastForecastRun?: string;
  }[],
  sort: { direction: string; name: string }
) {
  const datasetsToSort = [...datasets];
  if (sort.name === "name") {
    const sortedList = datasetsToSort.sort((a, b) => {
      if (sort.direction === "ascending") {
        return a.name.localeCompare(b.name);
      } else {
        return b.name.localeCompare(a.name);
      }
    });
    return sortedList;
  }
  if (sort.name === "status") {
    const sortedList = datasetsToSort.sort((a, b) => {
      if (sort.direction === "ascending") {
        return a.status.localeCompare(b.status);
      } else {
        return b.status.localeCompare(a.status);
      }
    });
    return sortedList;
  }
  if (sort.name === "source") {
    const sortedList = datasetsToSort.sort((a, b) => {
      if (sort.direction === "ascending") {
        return a.source.localeCompare(b.source);
      } else {
        return b.source.localeCompare(a.source);
      }
    });
    return sortedList;
  }
  if (sort.name === "uploadInterval") {
    const sortedList = datasetsToSort.sort((a, b) => {
      if (sort.direction === "ascending") {
        return a.uploadInterval.localeCompare(b.uploadInterval);
      } else {
        return b.uploadInterval.localeCompare(a.uploadInterval);
      }
    });
    return sortedList;
  }
  if (sort.name === "lastDataPoint") {
    const sortedList = datasetsToSort.sort((a, b) => {
      if (sort.direction === "ascending") {
        return (a.lastDataPoint || "").localeCompare(b.lastDataPoint || "");
      } else {
        return (b.lastDataPoint || "").localeCompare(a.lastDataPoint || "");
      }
    });
    return sortedList;
  }
  if (sort.name === "lastForecastRun") {
    const sortedList = datasetsToSort.sort((a, b) => {
      if (sort.direction === "ascending") {
        return (a.lastForecastRun || "").localeCompare(b.lastForecastRun || "");
      } else {
        return (b.lastForecastRun || "").localeCompare(a.lastForecastRun || "");
      }
    });
    return sortedList;
  }

  return datasets;
}

function DatasetList({
  datasets,
  format12h,
}: {
  datasets: {
    id: string;
    name: string;
    status: string;
    source: string;
    uploadInterval: string;
    lastDataPoint?: string;
    lastForecastRun?: string;
    datasetTimezone: string;
    forecastTimezone: string;
  }[];
  format12h: boolean;
}) {
  const [search, setSearch] = useState("");
  const [sort, setSort] = useState({ direction: "descending", name: "" });

  const defaultGranularity = "day";
  const defaultStartDate = Moment().startOf("day").unix().toString();
  const defaultEndDate = Moment().endOf("day").unix().toString();

  const filteredDatasets = search
    ? matchSorter(datasets, search, {
        keys: ["name"],
      })
    : handleSortDatasets(datasets, sort);

  function handleSort(name: string, direction: string) {
    setSort({ direction, name });
  }

  return (
    <div className={styles["datasets-container"]}>
      <div>
        <Search
          value={search}
          onChange={(e) => setSearch(e.target.value)}
          placeholder="Search datasets..."
        />
      </div>
      <div className={styles["datasets-list"]}>
        <div className={styles["datasets-list-header"]}>
          <div className={styles["datasets-list-header-name"]}>
            Name <SortUI name="name" sort={sort} handleSort={handleSort} />
          </div>
          <div className={styles["datasets-list-header-name"]}>
            Status
            <SortUI name="status" sort={sort} handleSort={handleSort} />
          </div>
          <div className={styles["datasets-list-header-name"]}>
            Source
            <SortUI name="source" sort={sort} handleSort={handleSort} />
          </div>
          <div className={styles["datasets-list-header-name"]}>
            Upload Interval
            <SortUI name="uploadInterval" sort={sort} handleSort={handleSort} />
          </div>
          <div className={styles["datasets-list-header-name"]}>
            Last Data Point
            <SortUI name="lastDataPoint" sort={sort} handleSort={handleSort} />
          </div>
          <div className={styles["datasets-list-header-name"]}>
            Last Forecast Run
            <SortUI
              name="lastForecastRun"
              sort={sort}
              handleSort={handleSort}
            />
          </div>
        </div>
        {filteredDatasets.length > 0 ? (
          filteredDatasets.map((dataset) => (
            <Link
              key={dataset.id}
              className={styles["datasets-list-item"]}
              to={`/forecasts/datasets/${dataset.id}?startDate=${defaultStartDate}&endDate=${defaultEndDate}&granularity=${defaultGranularity}`}
            >
              <div
                style={{
                  color: "rgba(0, 0, 0, 0.87)",
                  textDecoration: "none",
                  fontWeight: 500,
                }}
              >
                {dataset.name}
              </div>
              <div>
                <StatusLabel
                  status={dataset.status}
                  customStyle={{
                    marginTop: 20,
                  }}
                />
              </div>

              <div style={{ paddingTop: 6 }}>
                {dataset.source === "api" ? (
                  <ApiIcon width={20} />
                ) : (
                  <Csv01Icon width={20} />
                )}
              </div>
              <div>{utils.getInterval(dataset.uploadInterval)}</div>
              <div>
                {dataset.lastDataPoint
                  ? Moment(dataset.lastDataPoint).format(
                      `${format12h ? "h:mma" : "HH:mm"}, ddd, DD MMM`
                    )
                  : ""}
              </div>
              <div>
                {dataset.lastForecastRun
                  ? Moment(dataset.lastForecastRun).format(
                      `ddd, DD MMM [at] ${format12h ? "h:mma" : "HH:mm"}`
                    )
                  : ""}
              </div>
            </Link>
          ))
        ) : (
          <EmptyState
            icon="bi_interface-search"
            title="No results"
            message="No datasets found matching your search."
            customStyle={{ border: 0 }}
          />
        )}
      </div>
    </div>
  );
}

function SortUI(props: {
  handleSort: (name: string, direction: string) => void;
  name: string;
  sort: { direction: string; name: string };
}) {
  const arrowUp =
    props.sort.name === props.name && props.sort.direction === "ascending"
      ? UpActive
      : UpInActive;
  const arrowDown =
    props.sort.name === props.name && props.sort.direction === "descending"
      ? DownActive
      : DownInActive;
  return (
    <div style={{ float: "left", height: "32px" }}>
      <div
        style={{
          float: "left",
          width: "100%",
          height: "16px",
          lineHeight: "17px",
        }}
        onClick={() =>
          props.handleSort(
            props.name,
            props.sort.direction === "descending" ? "ascending" : "descending"
          )
        }
      >
        <img src={arrowUp} alt="Sort ascending" style={{ cursor: "pointer" }} />
      </div>
      <div
        style={{
          float: "left",
          width: "100%",
          height: "16px",
          lineHeight: "10px",
        }}
        onClick={() =>
          props.handleSort(
            props.name,
            props.sort.direction === "descending" ? "ascending" : "descending"
          )
        }
      >
        <img
          src={arrowDown}
          alt="Sort descending"
          style={{ cursor: "pointer" }}
        />
      </div>
    </div>
  );
}
