/** @jsxRuntime classic */
/** @jsxFrag React.Fragment */
/** @jsx jsx */
import { css, jsx } from "@emotion/core";
import CurrencyInput from "react-currency-input-field";

import { CSSProperties } from "react";
import styles from "./ToolBox.module.css";
import { colors } from "../../helpers/styles";
import Skeleton from "react-loading-skeleton";
import ToolTip from "./ToolTip";

function Input({
  id,
  label,
  value,
  type,
  name,
  handleChange,
  placeholder,
  pattern,
  patternSignifier,
  intlConfig,
  customStyle,
  accentColor,
  loading,
  disabled,
  readOnly,
  tooltip,
  labelIcon,
  isInvalid,
}: {
  id: string;
  label?: string;
  value: string;
  type?: string;
  name: string;
  handleChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
  placeholder?: string;
  pattern?: string;
  patternSignifier?: string;
  intlConfig?: {
    locale: string;
    currency: string;
  };
  customStyle?: CSSProperties;
  accentColor?: string;
  loading?: boolean;
  disabled?: boolean;
  readOnly?: boolean;
  tooltip?: {
    text: string;
    customStyle?: CSSProperties;
    customArrowStyle?: CSSProperties;
  };
  labelIcon?: React.ReactNode;
  isInvalid?: boolean | undefined;
}) {
  const accent = accentColor || colors.blue;

  let content;
  if (loading) {
    content = (
      <div
        css={css`
          height: 33px;
          border-bottom: ${readOnly ? "none" : "1px solid #e2e2e2"};
          box-sizing: border-box;
          padding-top: 3px;
        `}
      >
        <Skeleton height={21} />
      </div>
    );
  } else {
    if (type && type === "currency") {
      content = (
        <CurrencyInput
          id={id}
          name={name}
          placeholder={placeholder}
          defaultValue={1000}
          decimalsLimit={2}
          intlConfig={intlConfig}
          value={value}
          onValueChange={(value, name) => {
            const syntheticEvent = {
              target: {
                name,
                value,
              },
              preventDefault: () => {},
              stopPropagation: () => {},
            };

            handleChange(syntheticEvent);
          }}
          disabled={disabled || readOnly}
        />
      );
    } else {
      content = (
        <input
          id={id}
          name={name}
          value={value}
          onChange={handleChange}
          placeholder={placeholder}
          pattern={pattern}
          autoComplete="off"
          type={type}
          disabled={disabled || readOnly}
          data-is-invalid={isInvalid}
        />
      );
    }
  }

  return (
    <div
      className={styles["input-container"]}
      style={customStyle}
      css={css`
        input:focus {
          outline: none;
          border-bottom: 1px solid ${accent};
        }
        input:focus + label {
          color: ${accent};
        }
        ${readOnly &&
        css`
          input {
            border-bottom: none;
            cursor: default;
          }
          input:focus {
            outline: none;
            border-bottom: none;
          }
          input:focus + label {
            color: rgba(0, 0, 0, 0.54);
          }
        `}
      `}
    >
      {content}
      {label && (
        <label htmlFor={id}>
          {label}{" "}
          {tooltip && (
            <ToolTip
              text={tooltip.text}
              customStyle={tooltip.customStyle}
              customArrowStyle={tooltip.customArrowStyle}
            />
          )}
          {labelIcon && <span style={{ marginLeft: 3 }}>{labelIcon}</span>}
        </label>
      )}

      {patternSignifier && (
        <div
          className={styles["pattern-signifier"]}
          data-has-label={label ? "true" : "false"}
        >
          {patternSignifier}
        </div>
      )}
    </div>
  );
}

export default Input;
