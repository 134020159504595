import React, { useState, useMemo, useRef } from "react";
import { useParams, useLocation, Link, useHistory } from "react-router-dom";
import { gql, useQuery } from "@apollo/client";
import Moment from "moment-timezone";

import styles from "./Forecast.module.css";
import { colors } from "../../helpers/styles";

import PeriodSwitcher from "../toolBox/PeriodSwitcher";
import ToolTip from "../toolBox/ToolTip";
import OptionsMenuSubtitle from "../toolBox/OptionsMenuSubtitle";
import OutsideAlerter from "../clickOutside/OutsideAlerter";
import Calendar from "../calendar/Calendar2";
import StaffingRequirements from "./StaffingRequirements";
import LineChart from "./LineChart";
import Metrics from "./Metrics";
import DateRangeSwitcher from "./DateRangeSwitcher";

import { capitalizeString, unixToISOString } from "../../helpers/functions";
import utils from "./utils";
import StatusLabel from "./StatusLabel";
import StaffingParameters from "./StaffingParameters";
import Button from "../toolBox/Button";
import { useWindowSize } from "../../helpers/hooks";

export default function Dataset({
  datasets,
  format12h,
}: {
  datasets: { id: string; name: string }[];
  format12h: boolean;
}) {
  const windowSize = useWindowSize();
  const [sideBar, setSideBar] = useState<string | null>(null);
  const params: {
    id: string;
  } = useParams();
  const history = useHistory();
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);

  const dataset = datasets.find((dataset) => dataset.id === params.id);
  const staffingParameters = dataset?.staffingParameters;

  function handleUpdateSearchParams(newSearchParams: {
    startDate?: string;
    endDate?: string;
    comparisonStartDate?: string;
    comparisonEndDate?: string;
    granularity: string;
  }) {
    const searchParams = new URLSearchParams(location.search);
    if (newSearchParams.startDate) {
      searchParams.set("startDate", newSearchParams.startDate);
    }
    if (newSearchParams.endDate) {
      searchParams.set("endDate", newSearchParams.endDate);
    }

    const granularityChanged =
      searchParams.get("granularity") !== newSearchParams.granularity;
    searchParams.set("granularity", newSearchParams.granularity);
    if (newSearchParams.comparisonStartDate) {
      searchParams.set(
        "comparisonStartDate",
        newSearchParams.comparisonStartDate
      );
    }
    if (newSearchParams.comparisonEndDate) {
      searchParams.set("comparisonEndDate", newSearchParams.comparisonEndDate);
    }

    if (granularityChanged) {
      // we remove the comparison
      searchParams.delete("comparisonStartDate");
      searchParams.delete("comparisonEndDate");
    }

    history.push(`/forecasts/datasets/${params.id}?${searchParams.toString()}`);
  }

  const startDate = unixToISOString(searchParams.get("startDate")) || "";
  const endDate = unixToISOString(searchParams.get("endDate")) || "";
  const comparisonStartDate =
    unixToISOString(searchParams.get("comparisonStartDate")) || "";
  const comparisonEndDate =
    unixToISOString(searchParams.get("comparisonEndDate")) || "";
  const granularity = searchParams.get("granularity") || "";

  const { data, loading } = useQuery(GET_DATASET, {
    variables: {
      id: params.id,
      startDate: startDate,
      endDate: endDate,
      comparisonStartDate: comparisonStartDate,
      comparisonEndDate: comparisonEndDate,
    },
  });

  const forecast = data?.getDataset?.forecast || [];
  const comparison = data?.getDataset?.previousActuals || [];
  const actuals = data?.getDataset?.actuals || [];
  const staffingRequirements = data?.getDataset?.staffingRequirements || [];

  const chartData = {
    forecast: forecast,
    comparison: comparison,
    actuals: actuals,
    staffingRequirements: staffingRequirements,
  };

  const metaData = {
    interval: data?.getDataset?.meta?.interval,
    model: data?.getDataset?.meta?.model,
    lastRun: Moment(data?.getDataset?.meta?.lastRun).format(
      "ddd, DD MMM, YYYY"
    ),
  };

  const currentDataset = datasets.find((dataset) => dataset.id === params.id);
  let isSmallScreen = false;
  const windowSizeTreshold = sideBar ? 1330 + 375 : 1330;
  if (windowSize?.width && windowSize?.width < windowSizeTreshold) {
    isSmallScreen = true;
  }

  return (
    <div
      style={{
        height: "calc(100vh - 108px)",
        overflow: "hidden",
        display: "flex",
      }}
    >
      <div style={{ width: sideBar ? "calc(100% - 375px)" : "100%" }}>
        <div
          className={styles["forecast-header"]}
          style={{ display: "flex", justifyContent: "space-between" }}
        >
          <div style={{ display: "flex", gap: 12 }}>
            <div className={styles["forecast-header-title"]}>
              {currentDataset?.name}
            </div>
            <StatusLabel
              status={currentDataset?.status || "Inactive"}
              customStyle={{ marginTop: 6 }}
            />
            <div className={styles["forecast-header-back"]}>
              <span className="bi_interface-left" />{" "}
              <Link to="/forecasts/datasets">Back to All Datasets</Link>
            </div>
          </div>
          <RightMenu
            handleSideBar={(id: string) => {
              setSideBar(id);
            }}
          />
        </div>
        <Chart
          startDate={startDate}
          endDate={endDate}
          comparisonStartDate={comparisonStartDate}
          comparisonEndDate={comparisonEndDate}
          granularity={granularity}
          format12h={format12h}
          handleUpdateSearchParams={handleUpdateSearchParams}
          data={chartData}
          meta={metaData}
          loading={loading}
          isSmallScreen={isSmallScreen}
        />
      </div>
      {sideBar === "staffingParameters" && (
        <StaffingParameters
          handleClose={() => setSideBar(null)}
          staffingParameters={staffingParameters}
        />
      )}
    </div>
  );
}

function RightMenu({ handleSideBar }: { handleSideBar: (id: string) => void }) {
  const [moreOptionsMenu, setMoreOptionsMenu] = useState(false);
  return (
    <div style={{ position: "relative" }}>
      <Button
        size="small"
        name={
          <span style={{ color: "rgba(0, 0, 0, 0.87)" }}>
            More Options <span className="bi_interface-bottom" />
          </span>
        }
        theme="grey-border-black-text"
        action={() => {
          setMoreOptionsMenu(true);
        }}
      />
      {moreOptionsMenu && (
        <OptionsMenuSubtitle
          name="More Options"
          items={[
            {
              id: "staffingParameters",
              title: "Staffing Parameters",
              subtitle: "Adjust staffing parameters",
              action: () => {
                handleSideBar("staffingParameters");
              },
              // liStyle: {
              //   borderBottom: "1px solid rgba(0, 0, 0, 0.12)",
              //   marginBottom: 6,
              //   paddingBottom: 6,
              // },
            },
            // {
            //   id: "downloadDataset",
            //   title: "Download Dataset",
            //   subtitle: "Download the dataset as a CSV file",
            //   action: () => {
            //     //
            //   },
            // },
          ]}
          loading={false}
          handleFocus={() => {
            setMoreOptionsMenu(false);
          }}
          customStyle={{ width: 228, top: 0, right: 0 }}
        />
      )}
    </div>
  );
}

function Chart(props: {
  startDate: string;
  endDate: string;
  comparisonStartDate?: string;
  comparisonEndDate?: string;
  granularity: string;
  format12h: boolean;
  handleUpdateSearchParams: (newSearchParams: {
    startDate?: string;
    endDate?: string;
    comparisonStartDate?: string;
    comparisonEndDate?: string;
    granularity: string;
  }) => void;
  data: {
    forecast: {
      ds: string;
      forecast: number;
      upper_bound: number;
      lower_bound: number;
    }[];
    comparison: { ds: string; y: number }[];
    actuals: { ds: string; y: number }[];
    staffingRequirements: { ds: string; agentsNeeded: number }[];
  };
  meta: {
    interval: string;
    model: string;
    lastRun: string;
  };
  loading: boolean;
  isSmallScreen: boolean;
}) {
  const [linesHidden, setLinesHidden] = useState<string[]>([]);

  const scrollHeaderRef = useRef<HTMLDivElement>(null);

  function toggleLine(lines: string[]) {
    // if lines included in linesShown, remove them, otherwise add them
    let newLinesHidden = [...linesHidden];
    for (const line of lines) {
      if (newLinesHidden.includes(line)) {
        newLinesHidden = newLinesHidden.filter((l) => l !== line);
      } else {
        newLinesHidden.push(line);
      }
    }
    setLinesHidden(newLinesHidden);
  }

  const placeholderData = utils.getPlaceholderData(
    props.startDate,
    props.endDate,
    props.granularity
  );

  const datasets = props.loading
    ? [placeholderData]
    : [
        placeholderData,
        {
          id: "forecast",
          label: "Forecast",
          data: props.data.forecast.map((item) => {
            return {
              x: Moment.utc(item.ds, "YYYY-MM-DD HH:mm:ss").toDate(),
              y: item.forecast,
            };
          }),
          borderColor: colors.violet,
          borderWidth: 2,
          pointRadius: 0,
          hidden: linesHidden.includes("forecast"),
        },
        {
          id: "actual",
          label: "Actual",
          data: props.data.actuals.map((item) => {
            // temp because of data issues
            if (["day", "week"].includes(props.granularity)) {
              return {
                x: Moment.utc(item.ds, "YYYY-MM-DD HH:mm:ss").toDate(),
                y: item.y,
              };
            } else {
              return {
                x: Moment(item.ds, "YYYY-MM-DD HH:mm:ss").toDate(),
                y: item.y,
              };
            }
          }),
          borderColor: colors.orange,
          borderWidth: 2,
          pointRadius: 0,
          hidden: linesHidden.includes("actual"),
        },

        {
          id: "forecastUpper",
          label: "Forecast Upper",
          data: props.data.forecast.map((item) => {
            return {
              x: Moment.utc(item.ds, "YYYY-MM-DD HH:mm:ss").toDate(),
              y: item.upper_bound,
            };
          }),
          borderColor: "rgba(85, 81, 255, 0.10)",
          // borderDash: [5, 5],
          borderWidth: 0,
          backgroundColor: "rgba(85, 81, 255, 0.10)",
          fill: 4,
          pointRadius: 0,
          hidden: linesHidden.includes("forecastUpper"),
        },
        {
          id: "forecastLower",
          label: "Forecast Lower",
          data: props.data.forecast.map((item) => {
            return {
              x: Moment.utc(item.ds, "YYYY-MM-DD HH:mm:ss").toDate(),
              y: item.lower_bound,
            };
          }),
          borderColor: "rgba(85, 81, 255, 0.10)",
          // borderDash: [5, 5],
          borderWidth: 0,
          pointRadius: 0,
          hidden: linesHidden.includes("forecastLower"),
        },
        {
          id: "previousActual",
          label: "Previous Actual",
          data: props.data.comparison.map((item) => {
            let baseDate = Moment.utc(item.ds);

            const amountOfDaysDifference = Moment(props.startDate).diff(
              Moment(props.comparisonStartDate),
              "days"
            );
            baseDate = baseDate.add(amountOfDaysDifference, "days");

            return {
              x: baseDate,
              y: item.y,
              // originalDate: Moment.utc(item.ds),
            };
          }),
          borderColor: "grey",
          borderWidth: 2,
          pointRadius: 0,
          hidden: linesHidden.includes("previousActual"),
        },
      ];

  const allUniqueTimestamps = new Set();

  const forecastMetricsData = props.data.forecast.map((item) => {
    return {
      ...item,
      ds: item.ds.length < 11 ? item.ds + " 00:00:00" : item.ds,
    };
  });
  if (props.data.forecast.length > 0) {
    forecastMetricsData.forEach((item) => {
      const timestamp = item.ds.length < 11 ? item.ds + " 00:00:00" : item.ds;
      allUniqueTimestamps.add(timestamp);
    });
  }
  if (props.data.actuals.length > 0) {
    props.data.actuals.forEach((item) => {
      const timestamp = item.ds.length < 11 ? item.ds + " 00:00:00" : item.ds;
      allUniqueTimestamps.add(timestamp);
    });
  }

  const metricsData = [...allUniqueTimestamps].map((timestamp) => {
    return {
      ds: timestamp,
      actual:
        props.data.actuals.find((item) => item.ds === timestamp)?.y || null,
      forecast:
        forecastMetricsData.find((item) => item.ds === timestamp)?.forecast ||
        null,
    };
  });

  // if (props.data.forecast.length > 0) {
  //   metricsData = props.data.forecast
  //     .map((item) => {
  //       return {
  //         ds: item.ds,
  //         actual:
  //           props.data.actuals.find((actual) =>
  //             Moment.utc(actual.ds).isSame(Moment.utc(item.ds), "minute")
  //           )?.y || null,
  //         forecast: item.forecast,
  //       };
  //     })
  //     .filter((item) => item.actual !== null && item.forecast !== null);
  // } else if (props.data.actuals.length > 0) {
  //   metricsData = props.data.actuals.map((item) => {
  //     return {
  //       ds: item.ds,
  //       actual: item.y,
  //       forecast: null,
  //     };
  //   });
  // }

  const interval = utils.getInterval(props.meta.interval);
  let model = props.meta.model || "";
  if (model.endsWith("_week_average")) {
    model = model[0] + "-Week Average";
  } else {
    model = capitalizeString(model);
  }
  const lastRun = props.meta.lastRun;

  let rangeType = "day";
  if (props.granularity === "week") {
    rangeType = "isoweek";
  } else if (props.granularity === "month") {
    rangeType = "month";
  } else if (props.granularity === "quarter") {
    rangeType = "quarter";
  } else if (props.granularity === "year") {
    rangeType = "year";
  }

  const hasForecastData = props.data.forecast.length > 0;

  return (
    <div
      style={{
        padding: 24,
        display: "flex",
        flexDirection: "column",
      }}
    >
      <div
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
          paddingBottom: 24,
        }}
        ref={scrollHeaderRef}
      >
        <div style={{ display: "flex", gap: 24 }}>
          <DateRangeSwitcher
            startDate={props.startDate}
            endDate={props.endDate}
            granularity={props.granularity}
            handleUpdateSearchParams={props.handleUpdateSearchParams}
            isSmallScreen={props.isSmallScreen}
          />
          <div>
            <PeriodSwitcher
              setDateRange={(dateRange: {
                startDate: string;
                endDate: string;
                rangeType: string;
              }) => {
                props.handleUpdateSearchParams({
                  startDate: Moment(dateRange.startDate).unix().toString(),
                  endDate: Moment(dateRange.endDate).unix().toString(),
                  granularity: dateRange.rangeType,
                });
              }}
              startDate={props.startDate}
              endDate={props.endDate}
              switchMonths={(direction: "forward" | "back") => {
                utils.handleSwitchDateRange({
                  direction,
                  startDate: props.startDate,
                  endDate: props.endDate,
                  granularity: props.granularity,
                  handleUpdateSearchParams: props.handleUpdateSearchParams,
                });
              }}
              timezone={Moment.tz(Moment.tz.guess()).format("z")}
              rangeType={props.granularity}
              setRangeType={(rangeType: string) => {
                props.handleUpdateSearchParams({
                  granularity: rangeType,
                });
              }}
              updateUser={() => {}}
            />
          </div>
          {props.granularity !== "custom" && (
            <CompareButton
              currentDate={props.startDate}
              comparisonStartDate={props.comparisonStartDate}
              comparisonEndDate={props.comparisonEndDate}
              setCompareDate={(date: string) => {
                const startOfRange = Moment(date)
                  .startOf(rangeType)
                  .toISOString();
                const endOfRange = Moment(date).endOf(rangeType).toISOString();
                props.handleUpdateSearchParams({
                  startDate: Moment(props.startDate).unix().toString(),
                  endDate: Moment(props.endDate).unix().toString(),
                  comparisonStartDate: Moment(startOfRange).unix().toString(),
                  comparisonEndDate: Moment(endOfRange).unix().toString(),
                  granularity: props.granularity,
                });
              }}
              interval={props.granularity}
            />
          )}
        </div>
        <div className={styles["forecast-meta-data"]}>
          <ToolTip icon="i" />
          <div>
            Interval: <strong>{interval}</strong>
          </div>
          {hasForecastData && (
            <div className={styles["forecast-date-range-switcher-separator"]}>
              |
            </div>
          )}
          {hasForecastData && (
            <div>
              Model: <strong>{model}</strong>
            </div>
          )}
          {!props.isSmallScreen && (
            <div className={styles["forecast-date-range-switcher-separator"]}>
              |
            </div>
          )}
          {!props.isSmallScreen && (
            <div>
              Last Run: <strong>{lastRun}</strong>
            </div>
          )}
        </div>
      </div>
      <div
        className={styles["forecast-scroll-container"]}
        style={{ height: "calc(100vh - 291px)" }}
        onScroll={(e) => {
          if (e.target.scrollTop > 0) {
            if (scrollHeaderRef.current) {
              scrollHeaderRef.current.style.borderBottom = "1px solid #E0E0E0";
            }
          } else {
            if (scrollHeaderRef.current) {
              scrollHeaderRef.current.style.borderBottom = "none";
            }
          }
        }}
      >
        <LineChart
          info={{
            name: "Volume",
            units: "units",
          }}
          datasets={datasets}
          comparisonStartDate={props.comparisonStartDate}
          comparisonEndDate={props.comparisonEndDate}
          loading={props.loading}
          toggleLine={toggleLine}
          linesHidden={linesHidden}
          granularity={props.granularity}
          format12h={props.format12h}
          emptyState={null}
          range={{
            startDate: props.startDate,
            endDate: props.endDate,
            rangeType: props.granularity,
          }}
          isStacked={false}
          key={props.granularity + "dataset-volume"}
        />
        <ForecastMetrics data={metricsData} />
        {["15 min"].includes(interval) && (
          <StaffingRequirements
            forecast={props.data.forecast}
            agentsNeeded={props.data.staffingRequirements}
            startDate={props.startDate}
            endDate={props.endDate}
            granularity={props.granularity}
            format12h={props.format12h}
            range={{
              startDate: props.startDate,
              endDate: props.endDate,
              rangeType: props.granularity,
            }}
          />
        )}
      </div>
    </div>
  );
}

const ForecastMetrics = ({
  data,
}: {
  data: {
    actual: number;
    forecast: number;
  }[];
}) => {
  // Calculate metrics with useMemo to avoid recalculations unless data changes
  const calculations = useMemo(() => {
    if (!data || data.length === 0) {
      return {
        mape: 0,
        wape: 0,
        mae: 0,
        me: 0,
        rmse: 0,
        totalActualSum: 0,
        totalForecastSum: 0,
        forecastPeak: 0,
      };
    }

    let totalActual = 0;
    let totalForecast = 0;
    let totalAbsoluteError = 0;
    let totalPercentageError = 0;
    let totalSquaredError = 0;
    let meanError = 0;
    let forecastPeak = -Infinity;

    // Filter out invalid data points
    const onlyWithActuals = data.filter((item) => item.actual !== null);
    onlyWithActuals.forEach((item) => {
      const actual = item.actual;
      const forecast = item.forecast;

      const error = forecast - actual; // Signed error
      const absoluteError = Math.abs(error);

      // Accumulate values
      totalActual += actual;
      if (item.forecast !== null) {
        totalAbsoluteError += absoluteError;
        totalSquaredError += error ** 2;
        meanError += error;
      }

      if (actual !== 0) {
        // Avoid division by zero in MAPE
        totalPercentageError += absoluteError / actual;
      }
    });

    data.forEach((item) => {
      const forecast = item.forecast;

      totalForecast += forecast;
      if (forecast > forecastPeak) {
        forecastPeak = forecast; // Track the highest forecast value
      }
    });

    const n = onlyWithActuals.length; // Correct length for valid data

    // Calculate metrics
    const mae = totalAbsoluteError / n; // Mean Absolute Error
    const rmse = Math.sqrt(totalSquaredError / n); // Root Mean Squared Error
    const me = meanError / n; // Mean Error
    const wape = (totalAbsoluteError / totalActual) * 100; // Weighted Absolute Percentage Error
    const mape = (totalPercentageError / n) * 100; // Mean Absolute Percentage Error

    return {
      mape,
      wape: totalForecast === 0 ? 0 : wape,
      mae: totalForecast === 0 ? 0 : mae,
      rmse: totalForecast === 0 ? 0 : rmse,
      me: totalForecast === 0 ? 0 : me,
      totalActualSum: totalActual,
      totalForecastSum: totalForecast,
      forecastPeak,
    };
  }, [data]);

  const metrics = [
    { label: "WAPE", value: calculations.wape, isPercentage: true },
    { label: "Mean Absolute Error", value: calculations.mae },
    { label: "Mean Error", value: calculations.me },
    {
      label: "Forecast At Peak",
      value: calculations.forecastPeak,
      isRounded: true,
    },
    {
      label: "Total Forecasted",
      value: calculations.totalForecastSum,
      isRounded: true,
    },
    {
      label: "Total Actual",
      value: calculations.totalActualSum,
      isRounded: true,
    },
  ];

  return <Metrics metrics={metrics} />;
};

const GET_DATASET = gql`
  query getDataset(
    $id: ID!
    $startDate: DateTime!
    $endDate: DateTime!
    $comparisonStartDate: DateTime
    $comparisonEndDate: DateTime
  ) {
    getDataset(
      id: $id
      startDate: $startDate
      endDate: $endDate
      comparisonStartDate: $comparisonStartDate
      comparisonEndDate: $comparisonEndDate
    )
  }
`;

function CompareButton(props: {
  currentDate: string;
  setCompareDate: (date: string | null) => void;
  comparisonStartDate?: string;
  comparisonEndDate?: string;
  interval: string;
}) {
  const [menu, setMenu] = useState("");

  const isActive = props.comparisonStartDate && props.comparisonEndDate;

  let compareItems: {
    id: string;
    title: string;
    subtitle: string;
    action: () => void;
  }[] = [];
  let comparingToFormat = "ddd, D MMM";
  if (props.interval === "day") {
    compareItems = [
      {
        id: "previousDay",
        title: "Previous day",
        subtitle: Moment(props.currentDate)
          .subtract(1, "day")
          .format("ddd, D MMM"),
        action: () => {
          props.setCompareDate(
            Moment(props.currentDate).subtract(1, "day").toISOString()
          );
          setMenu("");
        },
      },
      {
        id: "previousWeek",
        title: "Previous week",
        subtitle: Moment(props.currentDate)
          .subtract(1, "week")
          .format("ddd, D MMM"),
        action: () => {
          props.setCompareDate(
            Moment(props.currentDate).subtract(1, "week").toISOString()
          );
          setMenu("");
        },
      },
      {
        id: "previousMonth",
        title: "Previous month",
        subtitle: Moment(props.currentDate)
          .subtract(1, "month")
          .format("ddd, D MMM"),
        action: () => {
          props.setCompareDate(
            Moment(props.currentDate).subtract(1, "month").toISOString()
          );
          setMenu("");
        },
      },
      {
        id: "previousQuarter",
        title: "Previous quarter",
        subtitle: Moment(props.currentDate)
          .subtract(1, "quarter")
          .format("ddd, D MMM"),
        action: () => {
          props.setCompareDate(
            Moment(props.currentDate).subtract(1, "quarter").toISOString()
          );
          setMenu("");
        },
      },
      {
        id: "previousYear",
        title: "Previous year",
        subtitle: Moment(props.currentDate)
          .subtract(1, "year")
          .format("ddd, D MMM, YYYY"),
        action: () => {
          props.setCompareDate(
            Moment(props.currentDate).subtract(1, "year").toISOString()
          );
          setMenu("");
        },
      },
      {
        id: "custom",
        title: "Custom",
        subtitle: "Select a date",
        action: () => {
          setMenu("custom");
        },
      },
    ];
  }
  if (props.interval === "week") {
    comparingToFormat = `[Week] w${
      !Moment(props.currentDate).subtract(1, "week").isSame(new Date(), "year")
        ? ", YYYY"
        : ""
    }`;
    compareItems = [
      {
        id: "previousWeek",
        title: "Previous week",
        subtitle: Moment(props.currentDate)
          .subtract(1, "week")
          .format(
            `[Week] w${
              !Moment(props.currentDate)
                .subtract(1, "week")
                .isSame(new Date(), "year")
                ? ", YYYY"
                : ""
            }`
          ), // week number
        action: () => {
          props.setCompareDate(
            Moment(props.currentDate).subtract(1, "week").toISOString()
          );
          setMenu("");
        },
      },
      {
        id: "previousMonth",
        title: "Previous month",
        subtitle: Moment(props.currentDate)
          .subtract(1, "month")
          .format(
            `[Week] w${
              !Moment(props.currentDate)
                .subtract(1, "month")
                .isSame(new Date(), "year")
                ? ", YYYY"
                : ""
            }`
          ),
        action: () => {
          props.setCompareDate(
            Moment(props.currentDate).subtract(1, "month").toISOString()
          );
          setMenu("");
        },
      },
      {
        id: "previousQuarter",
        title: "Previous quarter",
        subtitle: Moment(props.currentDate)
          .subtract(1, "quarter")
          .format(
            `[Week] w${
              !Moment(props.currentDate)
                .subtract(1, "quarter")
                .isSame(new Date(), "year")
                ? ", YYYY"
                : ""
            }`
          ),
        action: () => {
          props.setCompareDate(
            Moment(props.currentDate).subtract(1, "quarter").toISOString()
          );
          setMenu("");
        },
      },
      {
        id: "previousYear",
        title: "Previous year",
        subtitle: Moment(props.currentDate)
          .subtract(1, "year")
          .format(
            `[Week] w${
              !Moment(props.currentDate)
                .subtract(1, "year")
                .isSame(new Date(), "year")
                ? ", YYYY"
                : ""
            }`
          ),
        action: () => {
          props.setCompareDate(
            Moment(props.currentDate).subtract(1, "year").toISOString()
          );
          setMenu("");
        },
      },
    ];
  }
  if (props.interval === "month") {
    comparingToFormat = "MMMM";
    compareItems = [
      {
        id: "previousMonth",
        title: "Previous month",
        subtitle: Moment(props.currentDate)
          .subtract(1, "month")
          .format(
            `MMMM${
              !Moment(props.currentDate)
                .subtract(1, "month")
                .isSame(new Date(), "year")
                ? ", YYYY"
                : ""
            }`
          ),
        action: () => {
          props.setCompareDate(
            Moment(props.currentDate).subtract(1, "month").toISOString()
          );
          setMenu("");
        },
      },
      {
        id: "previousQuarter",
        title: "Previous quarter",
        subtitle: Moment(props.currentDate)
          .subtract(1, "quarter")
          .format(
            `MMMM${
              !Moment(props.currentDate)
                .subtract(1, "quarter")
                .isSame(new Date(), "year")
                ? ", YYYY"
                : ""
            }`
          ),
        action: () => {
          props.setCompareDate(
            Moment(props.currentDate).subtract(1, "quarter").toISOString()
          );
          setMenu("");
        },
      },
      {
        id: "previousYear",
        title: "Previous year",
        subtitle: Moment(props.currentDate)
          .subtract(1, "year")
          .format(
            `MMMM${
              !Moment(props.currentDate)
                .subtract(1, "year")
                .isSame(new Date(), "year")
                ? ", YYYY"
                : ""
            }`
          ),
        action: () => {
          props.setCompareDate(
            Moment(props.currentDate).subtract(1, "year").toISOString()
          );
          setMenu("");
        },
      },
    ];
  }
  if (props.interval === "quarter") {
    comparingToFormat = "[Q]Q";
    compareItems = [
      {
        id: "previousQuarter",
        title: "Previous quarter",
        subtitle: Moment(props.currentDate)
          .subtract(1, "quarter")
          .format(
            `[Q]Q${
              !Moment(props.currentDate)
                .subtract(1, "quarter")
                .isSame(new Date(), "year")
                ? ", YYYY"
                : ""
            }`
          ),
        action: () => {
          props.setCompareDate(
            Moment(props.currentDate).subtract(1, "quarter").toISOString()
          );
          setMenu("");
        },
      },
      {
        id: "previousYear",
        title: "Previous year",
        subtitle: Moment(props.currentDate)
          .subtract(1, "year")
          .format(
            `[Q]Q${
              !Moment(props.currentDate)
                .subtract(1, "year")
                .isSame(new Date(), "year")
                ? ", YYYY"
                : ""
            }`
          ),
        action: () => {
          props.setCompareDate(
            Moment(props.currentDate).subtract(1, "year").toISOString()
          );
          setMenu("");
        },
      },
    ];
  }
  if (props.interval === "year") {
    comparingToFormat = "YYYY";
    compareItems = [
      {
        id: "previousYear",
        title: "Previous year",
        subtitle: Moment(props.currentDate).subtract(1, "year").format("YYYY"),
        action: () => {
          props.setCompareDate(
            Moment(props.currentDate).subtract(1, "year").toISOString()
          );
          setMenu("");
        },
      },
    ];
  }

  return (
    <div style={{ position: "relative" }}>
      <div
        className={styles["forecast-compare-actuals"]}
        onClick={(e) => {
          const className = e.target.className;
          if (className.includes("bi_interface-cross")) {
            props.setCompareDate(null);
            setMenu("");
          } else {
            setMenu("compare");
          }
        }}
      >
        {isActive
          ? `Comparing to ${Moment(props.comparisonStartDate).format(
              comparingToFormat
            )}`
          : "Compare actuals"}
        {isActive ? (
          <span
            className="bi_interface-cross"
            style={{ marginLeft: 4 }}
            onClick={() => {
              props.setCompareDate(null);
              setMenu("");
            }}
          />
        ) : (
          <span className="bi_interface-bottom" />
        )}
      </div>
      {menu === "compare" && (
        <OptionsMenuSubtitle
          name="Compare to"
          items={compareItems}
          loading={false}
          handleFocus={() => {
            setMenu("");
          }}
          disableActionAutoClose={true}
          customStyle={{ width: 228, top: 48, left: 24 }}
        />
      )}
      {menu === "custom" && (
        <OutsideAlerter
          action={() => {
            setMenu("");
          }}
        >
          <div
            style={{
              position: "absolute",
              top: 0,
              right: 0,
              backgroundColor: "white",
              zIndex: 1000,
              width: 342,
              height: 306,
              padding: 12,
              boxSizing: "border-box",
              borderRadius: 5,
              boxShadow: "0px 1px 4px rgba(0, 0, 0, 0.25)",
            }}
          >
            <Calendar
              initialDate={Moment(props.currentCompareDate)}
              specificDaysSelected={[props.currentCompareDate]}
              setSpecificDay={(d) => {
                props.setCompareDate(Moment(d).toISOString());
                setMenu("");
              }}
              dots={[]}
            />
          </div>
        </OutsideAlerter>
      )}
    </div>
  );
}
