import LineChart from "./LineChart";
import Moment from "moment-timezone";
import { colors } from "../../helpers/styles";
import { ChartEvaluationIcon } from "@hugeicons/react";
import Metrics from "./Metrics";
// import { calculateRequiredAgents } from "./Erlang";

export default function StaffingRequirements(props: {
  forecast: { ds: string; forecast: number }[];
  agentsNeeded: { ds: string; agents: number }[];
  startDate: string;
  endDate: string;
  range: {
    startDate: string;
    endDate: string;
    rangeType: string;
  };
  granularity: string;
  format12h: boolean;
}) {
  // const serviceTime = 180; // Average Handle Time (seconds)
  // const targetServiceLevel = 0.8; // 80% service level
  // const thresholdTime = 20; // 20 seconds threshold

  // const agentsNeeded =
  //   props.granularity === "day"
  //     ? props.forecast.map((interval) => {
  //         if (interval.forecast === 0) {
  //           return {
  //             ds: interval.ds,
  //             agents: 0,
  //           };
  //         }
  //         const arrivalRate = interval.forecast / 900; // Calls per second (15 minutes = 900 seconds)
  //         const agents = calculateRequiredAgents(
  //           arrivalRate,
  //           serviceTime,
  //           targetServiceLevel,
  //           thresholdTime
  //         );

  //         return {
  //           ds: interval.ds,
  //           agents,
  //         };
  //       })
  //     : [];

  const placeholderData = {
    id: "placeholder",
    label: "",
    data: [
      {
        x: Moment(props.startDate).startOf(props.range).toDate(),
        y: 0,
      },
      {
        x: Moment(props.endDate).endOf(props.range).toDate(),
        y: 6,
      },
    ],
    borderColor: colors.orange,
    borderWidth: 0,
    pointRadius: 0,
  };

  const datasets = [
    placeholderData,
    {
      id: "forecast",
      label: "Forecast",
      data: props.agentsNeeded.map((item) => {
        return {
          x: Moment.utc(item.ds, "YYYY-MM-DD HH:mm:ss").toDate(),
          y: item.agentsNeeded,
        };
      }),
      borderColor: colors.violet,
      borderWidth: 2,
      pointRadius: 0,
      stepped: true,
    },
  ];

  let emptyState = null;
  if (props.forecast.length === 0) {
    emptyState = {
      component: <ChartEvaluationIcon />,
      title: "This chart has no data",
      description:
        "No forecast data is available to calculate staffing requirements.",
    };
  }
  return (
    <div
      style={{
        width: "100%",
        display: "flex",
        flexDirection: "column",
        gap: 24,
      }}
    >
      <LineChart
        info={{
          name: "Staffing Requirements",
          units: "people",
        }}
        datasets={datasets}
        loading={false}
        toggleLine={() => {}}
        linesHidden={[]}
        emptyState={emptyState}
        granularity={props.granularity}
        format12h={props.format12h}
        range={props.range}
        isStacked={false}
        key={props.granularity + "dataset-staffing"}
      />
      <StaffingMetrics data={props.agentsNeeded} />
    </div>
  );
}

function StaffingMetrics(props: {
  data: { ds: string; agentsNeeded: number }[];
}) {
  const interval = 15; // 15 minutes
  const metrics = [
    { label: "Min. Staffing", value: 0, isRounded: true },
    { label: "Max. Staffing", value: 0, isRounded: true },
    { label: "Mean Staffing", value: 0 },
    { label: "Total Staffing Hours", value: 0 },
  ];
  const data = props.data.filter((item) => item.agentsNeeded !== 0);
  if (data.length > 0) {
    const minAgents = Math.min(...data.map((item) => item.agentsNeeded));
    const maxAgents = Math.max(...props.data.map((item) => item.agentsNeeded));
    const meanAgents =
      data.reduce((sum, item) => sum + item.agentsNeeded, 0) / data.length;
    const totalStaffingHours = props.data.reduce((sum, item) => {
      const minutes = item.agentsNeeded * interval;
      const hours = minutes / 60;
      return sum + hours;
    }, 0);
    metrics[0].value = minAgents;
    metrics[1].value = maxAgents;
    metrics[2].value = meanAgents;
    metrics[3].value = totalStaffingHours;
  }

  return <Metrics metrics={metrics} />;
}
